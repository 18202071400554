@import "../style.scss";

.form-container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-dark-color);
  overflow: hidden;
  @include mobile {
    padding: 20px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 30px 60px;
    border-radius: 20px;
    @include mobile {
      padding: 30px 50px;
    }

    .title {
      font-size: 28px;
      font-weight: bold;
      color: var(--grey-light-color);
      margin-bottom: 10px;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      .avatar-wrapper {
        position: relative;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        margin: 15px 0 0;
        background-image: url(https://cdn.pixabay.com/photo/2016/11/13/21/46/sheep-1822137_1280.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: -25px;
        border: 3px solid var(--grey-mid-color);
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        #avatar {
          position: absolute;
          bottom: 0px;
          left: 0;
          outline: none;
          width: 100%;
          height: 50%;
          border: none;
          border-radius: 0;
          background-color: rgba(0, 0, 0, 0.377);
          cursor: pointer;
          transition: 0.4s;

          &:hover {
            height: 100%;
            &::before {
              transform: translateY(33px) scale(1.3);
            }
          }

          &::-webkit-file-upload-button {
            visibility: hidden;
          }

          &::before {
            content: "\f083";
            display: flex;
            justify-content: center;
            margin: 5px;
            font-family: fontAwesome;
            color: var(--overlay-secondary-color);
            font-size: 22px;
            user-select: none;
            transition: 0.4s ease;
          }
        }
      }

      #avatar-label {
        color: var(--grey-mid-color);
      }

      input {
        width: 250px;
        padding: 15px;
        border: none;
        background-color: var(--overlay-primary-color);
        border-radius: 10px;
        color: var(--white-color);

        &::placeholder {
          color: var(--grey-mid-color);
        }
      }

      button {
        width: 120px;
        height: 50px;
        margin: 20px 0;
        border-radius: 50px;
        background-color: var(--overlay-primary-color);
        border: none;
        color: var(--white-color);
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover {
          transform: translateY(-5px);
          box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.253);
        }
      }
    }

    #error-login {
      margin-bottom: 8px;
      padding: 5px 10px;
      background-color: var(--tertiary-color);
      border-radius: 50px;
      font-size: 14px;
      color: var(--white-color);
    }

    p {
      color: var(--grey-mid-color);
      font-size: 14px;

      a {
        color: var(--white-color);
        text-underline-offset: 6px;
      }
    }
  }
}
