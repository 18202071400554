@import "../style.scss";

.dashboard {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--overlay-primary-color);

  .container {
    display: flex;
    width: 85%;
    height: 80%;
    padding: 12px;
    border-radius: 10px;
    background-color: var(--overlay-secondary-color);
    box-shadow: 6px 6px 6px rgba(41, 41, 41, 0.616);
    overflow: hidden;
    @include mobile {
      position: relative;
      height: 100vh;
      width: 100%;
      border: 0;
      padding: 0;
      border-radius: 0;
      box-shadow: none;
    }

    .tool-bar {
      display: none;
      @include mobile {
        display: block;
        position: absolute;
        text-align: center;
        padding: 14.5px 10px;
        font-size: 20px;
        background-color: transparent;
        color: var(--grey-light-color);
        z-index: 100;
      }

      .tool-bar-btn {
        visibility: visible;
        position: relative;
        padding: 10px 15px;
        opacity: 1;
        transition: 1s ease 0.25s;
      }

      .tool-bar-btn--open {
        visibility: hidden;
        position: relative;
        padding: 10px 15px;
        opacity: 0;
        transition: 0.25s ease;
      }
    }

    .sidebar {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;
      @include mobile {
        position: absolute;
        flex: none;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        transform: translateX(-110%);
        -webkit-transition: all 0.25s cubic-bezier(0.7, -0.1, 0.33, 1.04) 0.25s;
        transition: all 0.25s cubic-bezier(0.7, -0.1, 0.33, 1.04) 0.25s;
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 85%;
          bottom: 0;
          background-color: var(--primary-dark-color);
          z-index: -10;
        }

        &--open {
          transform: translateX(0%);
          background-color: var(--secondary-dark-color);

          & ~ .chatbox {
            filter: blur(10px) opacity(0.5) brightness(0.5);
          }
        }
      }

      .navbar {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 69px;
        padding: 10px;
        margin: 7px 0px 23px 0;
        border-radius: 20px;
        background-color: var(--overlay-primary-color);
        color: var(--white-color);
        box-shadow: 6px 0px 0px #00000027;

        @include mobile {
          flex-direction: row-reverse;
          position: relative;
          align-self: start;
          height: 70px;
          margin: 0;
          padding: 16px;
          border-radius: 0;
          background-color: var(--secondary-dark-color);
        }

        .user {
          display: flex;
          align-items: center;
          gap: 14px;
          margin-right: 32px;
          @include mobile {
            flex-direction: row-reverse;
            width: calc(100% - 80px);
            margin-right: 0;
            justify-content: start;
          }

          .name {
            font-size: 18px;
            color: var(--white-color);
            @include mobile {
              font-size: 16px;
            }
          }

          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: var(--tertiary-color);
            object-fit: cover;
            overflow: hidden;
            @include mobile {
              width: 40px;
              height: 40px;
            }
          }
        }

        .dropdown {
          display: inline-block;

          .dropdown-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            background-color: transparent;
            border: none;
            transform: rotate(360deg);
            transition: all 1.2s ease-out;
            cursor: pointer;

            @include mobile {
              cursor: none;
            }

            &::before {
              content: "\f013";
              font-family: fontAwesome;
              font-size: 24px;
              color: var(--white-color);
            }

            &:hover {
              transform: rotate(0deg);
              transition: 1.2s ease;
              @include mobile {
                transition: 1.2s ease-out;
              }
            }
          }

          .dropdown-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: absolute;
            width: 82%;
            height: 100%;
            right: 0;
            top: 0;
            padding: 10px;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            background-color: var(--overlay-primary-color);
            @include mobile {
              height: 100%;
              left: 0;
              background-color: var(--secondary-dark-color);
              border-radius: 0;
            }

            ul {
              display: flex;
              justify-content: flex-end;
              gap: 20px;
              width: 100%;

              li {
                display: flex;
                align-items: center;
                padding: 10px 20px;
                height: 40px;
                width: 120px;
                background-color: var(--tertiary-color);
                border-radius: 10px;
                transition: 0.25s ease;
                cursor: pointer;
                &:hover {
                  background-color: var(--overlay-secondary-color);
                }

                @include mobile {
                  max-width: 110px;
                  padding: 10px 15px;
                }

                &.logout {
                  width: 120px;
                  display: flex;
                  justify-content: space-between;
                }

                .btn-theme {
                  width: 100%;

                  .fa-moon {
                    display: flex;
                    justify-content: space-between;
                    border-radius: 10px;
                    transition: 0.25s ease;
                    &:hover {
                      background-color: var(--overlay-secondary-color);
                    }

                    @include mobile {
                      padding: 0;
                      gap: 0;
                      border-radius: 0;
                    }

                    &::after {
                      content: "Dark";
                      font-family: "Roboto", sans-serif;
                      font-weight: normal;
                    }
                  }

                  .fa-sun {
                    display: flex;
                    justify-content: space-between;
                    &:hover {
                      background-color: var(--overlay-secondary-color);
                    }

                    @include mobile {
                      padding: 0;
                      gap: 0;
                      border-radius: 0;
                    }

                    &::after {
                      content: "Light";
                      font-family: "Roboto", sans-serif;
                      font-weight: normal;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .searchbar {
        width: 90%;
        @include mobile {
          width: 100%;
          margin-bottom: 10px;
          padding: 20px 10px;
          border-radius: 30px 30px 0 0;
          background-color: var(--primary-dark-color);
          &:before {
            content: "";
            position: absolute;
            width: 100%;
            min-height: 100vh;
            bottom: 0;
            left: 0;
            background-color: var(--secondary-dark-color);
            z-index: -100;
          }
        }

        .search-form {
          display: flex;
          align-items: center;
          width: 100%;
          min-height: 48px;
          padding: 8px;
          margin-top: 15px;
          background-color: var(--primary-dark-color);
          border-radius: 10px;
          @include mobile {
            margin-top: 0;
            border-radius: 50px;
            background-color: var(--overlay-secondary-color);
          }

          .go-search {
            height: 39px;
            min-width: 39px;
            padding: 10px;
            border-radius: 6px;
            color: var(--grey-mid-color);
            background-color: var(--overlay-primary-color);
            border: none;
            transition: 0.15s ease 0.25s;
            cursor: pointer;
            &:hover {
              scale: 0.9;
            }
            @include mobile {
              border-radius: 50px;
            }

            i {
              font-size: 18px;
            }
          }

          input {
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;
            margin: 10px;
            background-color: transparent;
            border: none;
            color: var(--grey-light-color);
            outline: none;

            &::placeholder {
              padding: 5px;
              color: var(--grey-mid-color);
            }
          }
        }

        .search-result {
          width: 100%;
          padding: 10px;
          background-color: var(--primary-dark-color);
          margin-top: -30px;
          border-radius: 0 0 20px 20px;
          @include mobile {
            background-color: var(--overlay-secondary-color);
          }

          .notFound {
            width: 100%;
            text-align: center;
            margin-top: 10px;
            color: var(--tertiary-color);
          }

          .user-chat {
            display: flex;
            align-items: center;
            padding: 15px 20px;
            margin-top: 15px;
            gap: 18px;
            color: var(--white-color);
            background-color: var(--primary-dark-color);
            border-radius: 10px;
            cursor: pointer;
            transition: 0.25s ease;
            @include mobile {
              position: relative;
              margin-top: 30px;
              background-color: var(--tertiary-color);
              border-radius: 15px;
              &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 1.5px;
                background-color: var(--primary-mid-color);
                top: -10px;
                left: 0;
              }
            }

            &:hover {
              transform: scale(0.98);
            }

            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              object-fit: cover;
              background-color: var(--tertiary-color);
              overflow: hidden;
            }

            .user-chat-info {
              span {
                font-size: 18px;
                font-weight: 500;
              }

              p {
                font-size: 14px;
              }
            }
          }
        }
      }

      .contact {
        width: 90%;
        margin-top: 35px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
        @include mobile {
          width: 100%;
          height: 100%;
          margin-top: 0;
          padding: 10px 10px;
          background-color: var(--primary-dark-color);
        }

        .user-chat {
          display: flex;
          align-items: center;
          padding: 15px 20px;
          margin: 15px 0;
          gap: 22px;
          color: var(--white-color);
          background-color: var(--secondary-dark-color);
          border-radius: 10px;
          cursor: pointer;
          transition: 0.25s ease;
          @include mobile {
            background-color: var(--secondary-dark-color);
            padding: 15px;
            margin: 0px 0px 10px;
            border-radius: 20px;
            cursor: none;
          }

          &:hover {
            transform: translateX(5px);
          }

          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
            background-color: var(--tertiary-color);
            overflow: hidden;
          }

          .user-chat-info {
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: calc(100% - 75px);

            span {
              font-size: 16px;
              font-weight: 500;
            }

            .last-content {
              max-width: 200px;
              padding-right: 20px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 14px;
              color: var(--grey-mid-color);
            }
          }
        }
      }
    }

    .chatbox {
      flex: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 14px;
      @include mobile {
        flex: none;
        width: 100%;
        padding-left: 0;
        padding-bottom: 12px;
        background-color: var(--secondary-dark-color);
        transition: 0.35s ease-out;
        overflow: hidden;
      }

      .chat-info {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: flex-end;
        height: 80px;
        width: calc(100% + 52px);
        padding: 10px;
        margin: 2px 52px 20px 0;
        border-radius: 20px;
        background-color: var(--tertiary-color);
        @include mobile {
          width: 100%;
          align-self: flex-start;
          margin: 0;
          padding: 10px;
          background-color: transparent;
          box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.123);
          border-radius: 0;
        }

        .contact-name {
          display: flex;
          align-items: center;
          margin-right: 20px;
          font-size: 16px;
          font-weight: normal;
          color: var(--grey-light-color);
          gap: 15px;
          @include mobile {
            justify-content: center;
            width: 100%;
            margin-right: 0;
          }

          img {
            min-width: 56px;
            max-height: 56px;
            border-radius: 50px;
            background-color: var(--tertiary-color);
            object-fit: cover;
            overflow: hidden;
            @include mobile {
              display: none;
            }
          }

          .display-name {
            font-size: 18px;
            font-weight: bold;
            color: var(--white-color);
            @include mobile {
              color: var(--white-color);
              font-weight: normal;
              font-size: 16px;
            }
          }
        }

        .chat-icons {
          display: flex;
          gap: 10px;

          i {
            width: 28px;
            font-size: 20px;
            cursor: pointer;
            color: var(--white-color);
          }
        }
      }

      .messages {
        padding: 20px 20px 50px;
        background-color: var(--secondary-dark-color);
        height: calc(100% - 120px);
        width: 100%;
        border-radius: 20px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
        @include mobile {
          padding: 10px;
          height: calc(100% - 60px);
          margin: 8px 0px;
          border-radius: 0px;
        }

        .display-msg {
          display: flex;
          gap: 10px;
          margin: 10px 0;
          transition: 0.35s ease;

          .display-msg-info {
            display: flex;
            flex-direction: column;
            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              object-fit: cover;
              background-color: var(--overlay-primary-color);
              overflow: hidden;
              @include mobile {
                width: 40px;
                height: 40px;
              }
            }
          }

          .display-msg-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 80%;
            gap: 3px;

            p {
              max-width: max-content;
              padding: 10px 20px;
              background-color: var(--tertiary-color);
              color: var(--white-color);
              border-radius: 0px 10px 10px 10px;
              font-size: 16px;
              font-weight: normal;
              @include mobile {
                font-size: 14px;
              }
            }

            img {
              max-width: 200px;
              height: auto;
              object-fit: cover;
              background-color: var(--overlay-secondary-color);
              border-radius: 0px 10px 10px 10px;
              @include mobile {
                max-width: 150px;
              }
            }

            .timer {
              font-size: 10px;
              color: var(--grey-mid-color);
              margin-left: 10px;

              &.show--timer {
                display: block;
              }
            }
          }

          &.owner {
            flex-direction: row-reverse;
            gap: 0;

            .display-msg-info {
              display: flex;
              flex-direction: column;

              img {
                display: none;
              }
            }

            .display-msg-content {
              align-items: flex-end;

              p {
                background-color: var(--overlay-primary-color);
                color: var(--white-color);
                border-radius: 10px;
              }

              img {
                border-radius: 10px;
                @include mobile {
                  max-width: 150px;
                }
              }

              .timer {
                font-size: 10px;
                color: var(--grey-mid-color);
                margin-right: 10px;

                &.show--timer {
                  display: block;
                }
              }
            }
          }
        }
      }

      .input-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        height: 60px;
        width: 90%;
        margin-top: -35px;
        outline: none;
        border-radius: 50px;
        background-color: var(--primary-light-color);
        z-index: 0;
        @include mobile {
          width: calc(100% - 92px);
          height: 46px;
          align-self: flex-end;
          margin-top: 0;
          margin-right: 15px;
          box-shadow: none;
        }

        label {
          padding: 20px;
          @include mobile {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            transform: translateX(-62px);
            width: 46px;
            height: 46px;
            background-color: var(--primary-light-color);
            border-radius: 50%;
          }
        }

        .fa-images {
          margin-top: 1px;
          font-size: 18px;
          color: var(--grey-mid-color);
          transition: 0.25s ease;
          cursor: pointer;
          @include mobile {
            color: var(--grey-light-color);
          }
          &:hover {
            color: var(--grey-light-color);
          }
        }

        input {
          &[type="text"] {
            width: 100%;
            margin-left: 20px;
            border: none;
            outline: none;
            font-size: 18px;
            background-color: transparent;
            color: var(--grey-light-color);
            @include mobile {
              margin-left: 15px;
              font-size: 16px;
            }

            &::placeholder {
              color: var(--grey-mid-color);
            }
          }

          &[type="file"] {
            width: 110px;
            font-size: 12px;
            color: var(--grey-mid-color);
            @include mobile {
              display: none;
            }

            &::-webkit-file-upload-button {
              display: none;
            }
          }
        }

        .send {
          display: flex;
          align-items: center;
          height: 100%;

          button {
            width: 60px;
            height: 60px;
            padding-right: 10px;
            border-radius: 50%;
            border: none;
            background-color: transparent;
            cursor: pointer;
            @include mobile {
              height: 46px;
              width: 46px;
              padding-right: 6px;
              background-color: transparent;
            }

            .fa-comment {
              font-size: 26px;
              color: var(--grey-light-color);
              transition: 0.25s ease;
              @include mobile {
                font-size: 24px;
              }
            }
            &:hover > .fa-comment {
              color: var(--grey-mid-color);
            }
          }
        }
      }
    }
  }
}
