@import "../style.scss";

.form-container {
  overflow: hidden;

  .avatar-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    transform: translate(0, -220px);
  }

  &.login {
    img {
      width: 120px;
      height: 120px;
      margin: 10px 0 15px;
      border-radius: 100px;
      object-fit: cover;
      object-position: -25px center;
      border: 4px solid var(--grey-mid-color);
    }
  }
}
