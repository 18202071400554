* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  list-style-type: none;
}

// mixins

@mixin mobile {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

//  colors variables

:root {
  --primary-dark-color: #e4e4e4;
  --primary-mid-color: #2c2c2c;
  --primary-light-color: #cccccc;
  --secondary-dark-color: #f0f0f0;
  --tertiary-color: #aacafa;
  --overlay-tertiary-color: #bebdc9;
  --overlay-secondary-color: #ffffff;
  --overlay-primary-color: #d3d3d3;
  --white-color: #000000;
  --grey-light-color: #242424;
  --grey-mid-color: #505050;
}

[data-theme="dark"] {
  --primary-dark-color: #141414;
  --primary-mid-color: #2c2c2c;
  --primary-light-color: #535353;
  --secondary-dark-color: #1d1d1d;
  --tertiary-color: #8e3838;
  --overlay-tertiary-color: #221c47;
  --overlay-secondary-color: #000000;
  --overlay-primary-color: #3c3569;
  --white-color: #ffffff;
  --grey-light-color: #f4f4f4;
  --grey-mid-color: #b4b4b4;
}
